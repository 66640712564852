import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Typography, Box, Stack, Button } from '@mui/material'
import Layout from 'components/Layout'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'style/theme'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import Patient01 from './patientComponents/Patient01'
import Patient02 from './patientComponents/Patient02'
import Patient03 from './patientComponents/Patient03'
import Patient04 from './patientComponents/Patient04'
import Patient05 from './patientComponents/Patient05'
import { CompleteModal, CompleteModalOnCloseProps, CompleteModalProps } from 'components/CompleteModal'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { ImageType, skeletalImages, faceTypeImages, angleRightImages, angleLeftImages, otherElementImages } from 'types/PatientObjects'
import { usePostPatientStep1, usePostPatientStep2, usePostPatientStep3, usePostPatientStep4, usePostPatient } from 'api/patient/index'
import { FirstValidatorErrorItem, FourthValidatorErrorItem, PostPatientFirstStepCheckResponse, PostPatientFourthStepCheckResponse, PostPatientSecondStepCheckResponse, PostPatientThirdStepCheckResponse, PostPatientUpsertResponse, SecondValidatorErrorItem, ThirdValidatorErrorItem } from 'api/patient/responseType'
import { AxiosError } from 'axios'
import OperationProof from 'components/OperationProof'
import { ErrorModal, ErrorModalOnCloseProps, ErrorModalProps } from 'components/ErrorModal'
import useResetQuery from 'api/useResetQuery'

type AddPatientProps = {
  clinicId: string
}

type ChangeFunctionsType = {
  [key: string]: [Function, Function]
}

type ChangeStateFunctionsType = {
  [key: string]: [Function, Function, ImageType[]]
}

const AddPatient = () => {
  const urlParams = useParams<AddPatientProps>()
  const clinicId = urlParams.clinicId ?? ''
  const [ page, setPage ] = useState(1)
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.tz.guess()
  const [ patientNo, setPatientNo ] = useState<string>('')
  const [ patientNoError, setPatientNoError ] = useState<string[]>([])
  const [ name, setName ] = useState<string>('')
  const [ nameError, setNameError ] = useState<string[]>([])
  const [ kana, setKana ] = useState<string>('')
  const [ kanaError, setKanaError ] = useState<string[]>([])
  const [ sex, setSex ] = useState<number>(0)
  const [ sexError, setSexError ] = useState<string[]>([])
  const [ birthday, setBirthday ] = useState<Dayjs>(dayjs().subtract(20, 'year'))
  const [ birthdayError, setBirthdayError ] = useState<string[]>([])
  const [ skeletal, setSkeletal ] = useState<number | undefined>(99)
  const [ skeletalError, setSkeletalError ] = useState<string[]>([])
  const [ faceType, setFaceType ] = useState<number | undefined>(99)
  const [ faceTypeError, setFaceTypeError ] = useState<string[]>([])
  const [ angleRight, setAngleRight ] = useState<number | undefined>(99)
  const [ angleRightError, setAngleRightError ] = useState<string[]>([])
  const [ angleLeft, setAngleLeft ] = useState<number | undefined>(99)
  const [ angleLeftError, setAngleLeftError ] = useState<string[]>([])
  const [ otherElement, setOtherElement ] = useState<number[]>([])
  const [ otherElementError, setOtherElementError ] = useState<string[]>([])
  const [ renderSkeletal, setRenderSkeletal ] = useState<ImageType | undefined>(
    skeletalImages.find(item => item.key === skeletal)
  )
  const [ renderFaceType, setRenderFaceType ] = useState<ImageType | undefined>(
    faceTypeImages.find(item => item.key === faceType)
  )
  const [ renderAngleRight, setRenderAngleRight ] = useState<ImageType | undefined>(
    angleRightImages.find(item => item.key === angleRight)
  )
  const [ renderAngleLeft, setRenderAngleLeft ] = useState<ImageType | undefined>(
    angleLeftImages.find(item => item.key === angleLeft)
  )
  const [ renderOtherElement, setRenderOtherElement ] = useState<ImageType[]>(
    otherElementImages.filter(item => otherElement.includes(item.key))
  )
  const { mutate: postStepMutate1 } = usePostPatientStep1()
  const { mutate: postStepMutate2 } = usePostPatientStep2()
  const { mutate: postStepMutate3 } = usePostPatientStep3()
  const { mutate: postStepMutate4 } = usePostPatientStep4()
  const { mutate: postMutate } = usePostPatient()
  const [completeModalConfig, setCompleteModalConfig] = useState<CompleteModalProps | undefined>()
  const [operationProofing, setOperationProofing] = useState(false)
  const [errorModalConfig, setErrorModalConfig] = useState<ErrorModalProps | undefined>()
  const navigate = useNavigate()
  const resetQuery = useResetQuery()

  const changeFunctions: ChangeFunctionsType = {
    'patientNo': [setPatientNo, setPatientNoError],
    'name': [setName, setNameError],
    'kana': [setKana, setKanaError],
    'sex': [setSex, setSexError],
  }

  const changeStateFunctions: ChangeStateFunctionsType = {
    'skeletal': [setSkeletal, setRenderSkeletal, skeletalImages],
    'faceType': [setFaceType, setRenderFaceType, faceTypeImages],
    'angleRight': [setAngleRight, setRenderAngleRight, angleRightImages],
    'angleLeft': [setAngleLeft, setRenderAngleLeft, angleLeftImages],
    'otherElement': [setOtherElement, setRenderOtherElement, otherElementImages]
  }

  const handleNext = async () => {
    setOperationProofing(true)
    switch (page) {
      case 1:
        postStepMutate1(
          { req: {patientNo} },
          {
            onSuccess: (response: PostPatientFirstStepCheckResponse) => {
              if (response.status === 'OK') {
                setPatientNoError([])
                setPage(page + 1)
              } else {
                alert('予期せぬエラーが発生しました。')
              }
            },
            onError: (e: AxiosError<PostPatientFirstStepCheckResponse>) => {
              const errors: FirstValidatorErrorItem | undefined = e.response?.data.errors
              setPatientNoError(errors?.patient_no ?? [])
            },
            onSettled: () => {
              setOperationProofing(false)
            }
          }
        )
        break
      case 2:
        postStepMutate2(
          { req: {name, kana} },
          {
            onSuccess: (response: PostPatientSecondStepCheckResponse) => {
              if (response.status === 'OK') {
                setPatientNoError([])
                setPage(page + 1)
              } else {
                alert('予期せぬエラーが発生しました。')
              }
            },
            onError: (e: AxiosError<PostPatientSecondStepCheckResponse>) => {
              const errors: SecondValidatorErrorItem | undefined = e.response?.data.errors
              setNameError(errors?.name ?? [])
              setKanaError(errors?.kana ?? [])
            },
            onSettled: () => {
              setOperationProofing(false)
            }
          }
        )
        break
      case 3:
        postStepMutate3(
          { req: {sex, birthday: birthday.toDate()} },
          {
            onSuccess: (response: PostPatientThirdStepCheckResponse) => {
              if (response.status === 'OK') {
                setPatientNoError([])
                setPage(page + 1)
              } else {
                alert('予期せぬエラーが発生しました。')
              }
            },
            onError: (e: AxiosError<PostPatientThirdStepCheckResponse>) => {
              const errors: ThirdValidatorErrorItem | undefined = e.response?.data.errors
              setSexError(errors?.sex ?? [])
              setBirthdayError(errors?.birthday ?? [])
            },
            onSettled: () => {
              setOperationProofing(false)
            }
          }
        )
        break
      case 4:
        const req = {
          skeletal: skeletal === 99 ? undefined : skeletal,
          faceType: faceType === 99 ? undefined : faceType,
          angleRight: angleRight === 99 ? undefined : angleRight,
          angleLeft: angleLeft === 99 ? undefined : angleLeft,
          otherElement
        }
        postStepMutate4(
          { req },
          {
            onSuccess: (response: PostPatientFourthStepCheckResponse) => {
              if (response.status === 'OK') {
                setPatientNoError([])
                setPage(page + 1)
              } else {
                alert('予期せぬエラーが発生しました。')
              }
            },
            onError: (e: AxiosError<PostPatientFourthStepCheckResponse>) => {
              const errors: FourthValidatorErrorItem | undefined = e.response?.data.errors
              setSkeletalError(errors?.skeletal ?? [])
              setFaceTypeError(errors?.face_type ?? [])
              setAngleRightError(errors?.angle_right ?? [])
              setAngleLeftError(errors?.angle_left ?? [])
              setOtherElementError(errors?.other_element ?? [])
            },
            onSettled: () => {
              setOperationProofing(false)
            }
          }
        )
        break
      default:
        setOperationProofing(false)
        break
    }
  }

  const handlePrev = () => {
    setPage(page - 1)
  }

  const handleJump = (event: React.MouseEvent<HTMLInputElement>, jumpTo: number) => {
    setPage(jumpTo)
  }

  const handleConfirm = async () => {
    setOperationProofing(true)
    postMutate(
      {
        req: {
          patientNo, name, kana, sex, birthday: birthday.format('YYYY-MM-DD'),
          skeletal: skeletal === 99 ? undefined : skeletal,
          faceType: faceType === 99 ? undefined : faceType,
          angleRight: angleRight === 99 ? undefined : angleRight,
          angleLeft: angleLeft === 99 ? undefined : angleLeft,
          otherElement
        }
      },
      {
        onSuccess: async (response: PostPatientUpsertResponse) => {
          setOperationProofing(false)
          if (response.status === 'OK') {
            await new Promise<CompleteModalOnCloseProps>((resolve) => {
              setCompleteModalConfig({
                onClose: resolve,
                title: `登録が完了しました。`
              })
            })
            setCompleteModalConfig(undefined)

            resetQuery(['patientList'])
            resetQuery(['patientIndexes'])

            navigate(`/${clinicId}/patient`)
          } else {
            await new Promise<ErrorModalOnCloseProps>((resolve) => {
              setErrorModalConfig({
                onClose: resolve,
                title: '登録に失敗しました。'
              })
            })
            setErrorModalConfig(undefined)
          }
        },
        onError: async (error: AxiosError) => {
          setOperationProofing(false)
          await new Promise<ErrorModalOnCloseProps>((resolve) => {
            setErrorModalConfig({
              onClose: resolve,
              title: '登録に失敗しました。'
            })
          })
          setErrorModalConfig(undefined)

        }
      }
    )
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, variables: string) => {
    const [changeFunc, resetError] = changeFunctions[variables]
    changeFunc(event.target.value)
    resetError([])
  }

  const handleChangeState = (event: React.ChangeEvent<HTMLInputElement>, variables: string) => {
    const [setValueFunc, setRenderFunc, targetImages] = changeStateFunctions[variables]
    if (variables === 'otherElement') {
      let otherElementNow = structuredClone(otherElement)
      if (event.target.checked) {
        otherElementNow.push(parseInt(event.target.value))
      } else {
        otherElementNow = otherElementNow.filter((item) => item !== parseInt(event.target.value))
      }
      otherElementNow.sort((a, b) => a - b)
      setValueFunc(otherElementNow)
      setRenderFunc(targetImages.filter(item => otherElementNow.includes(item.key)))
    } else {
      setValueFunc(parseInt(event.target.value))
      setRenderFunc(targetImages.find(item => item.key === parseInt(event.target.value)))
    }
  }

  const handleChangeDate = (date: Dayjs) => {
    setBirthday(date)
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1, mb: 4  }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <PersonAddIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>新しい患者を追加</Typography>
            </Box>
            <Patient01 show={page === 1} handleChange={handleChange}
              patientNo={patientNo} patientNoError={patientNoError}
            />
            <Patient02 show={page === 2} handleChange={handleChange}
              name={name} kana={kana} nameError={nameError} kanaError={kanaError}
            />
            <Patient03 show={page === 3} handleChange={handleChange} handleChangeDate={handleChangeDate}
              sex={sex} birthday={birthday} sexError={sexError} birthdayError={birthdayError}
            />
            <Patient04 show={page === 4} handleChange={handleChangeState}
              skeletal={skeletal} faceType={faceType} angleRight={angleRight} angleLeft={angleLeft} otherElement={otherElement}
              skeletalError={skeletalError} faceTypeError={faceTypeError} angleRightError={angleRightError} angleLeftError={angleLeftError} otherElementError={otherElementError}
            />
            <Patient05 show={page === 5}
              patientNo={patientNo} name={name} kana={kana} sex={sex} birthday={birthday}
              renderSkeletal={renderSkeletal} renderFaceType={renderFaceType}
              renderAngleRight={renderAngleRight} renderAngleLeft={renderAngleLeft} renderOtherElement={renderOtherElement}
              handleJump={handleJump}
            />
            <Stack sx={{ alignItems: "center", columnGap: "24px", justifyContent: "flex-end", mt: 4 }} direction="row" >
              <Button
                onClick={handlePrev}
                size="medium"
                variant="outlined"
                sx={{ display: page !== 1 ? "block" : "none" ,textAlign: "center", fontSize: "14px", borderRadius: "40px", px: 8, fontWeight: "bold", "&:hover": { background: "#11867F", color: "white" } }}>
                  戻る
              </Button>
              <Button
                onClick={handleNext}
                size="medium"
                sx={{ display: page !== 5 ? "block" : "none"  ,textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", "&:hover": { background: "#11867F" } }}>
                  次へ
              </Button>
              <Button
                onClick={handleConfirm}
                size="medium"
                sx={{ display: page === 5 ? "block" : "none"  ,textAlign: "center", fontSize: "14px", color: "white", background: theme.palette.primary.main, borderRadius: "40px", px: 8, fontWeight: "bold", "&:hover": { background: "#11867F" } }}>
                  登録する
              </Button>
            </Stack>
          </Box>
          {completeModalConfig && <CompleteModal {...completeModalConfig} />}
          {errorModalConfig && <ErrorModal {...errorModalConfig} />}
          {operationProofing && <OperationProof initial={false} />}
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default AddPatient
