import React from 'react';
import { Typography, Stack } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { NavLink, useParams } from "react-router-dom";
import useResetQuery from 'api/useResetQuery'


const SettingNewsListItem = (props : any) => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const resetQuery = useResetQuery();

  return (
    <>
      <ListItemButton
        onClick={() => resetQuery(['informationList'])}
        role="listitem"
        to={`/${clinicId}/setting/news/post/` + props.newsItemData.id}
        component={NavLink}
        sx={{ p: 3 }}
      >
        <Stack direction="column" spacing={1} >
          <Typography sx={{ background: props.newsItemData.read ? "#00BCC8" : "#EC5264", color: "white", fontWeight: "bold", fontSize: "12px", borderRadius:"26px", py: "2px", px: 2, width: "54px", textAlign: "center" }}>{props.newsItemData.read ? "開封済み" : "未読"}</Typography>
          <ListItemText primary={props.newsItemData.title} secondary={props.newsItemData.publishDatetime} />
        </Stack>
      </ListItemButton>
    </>
  )
}
export default SettingNewsListItem;
