import React, { useState, useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import Layout from '../../components/Layout'
import PageBackButton from '../../components/PageBackButton'
import { ThemeProvider } from '@mui/material/styles'
import theme from '../../style/theme'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { useParams } from "react-router-dom"
import type { Information }  from '../../api/information/responseType'
import { useGetInformation } from '../../api/information'
import OperationProof from 'components/OperationProof'
import parse from 'html-react-parser'

type NewsProps = {
  id: string;
  clinicId: string;
}

const SettingNewsListPost = () => {
  const urlParams = useParams<NewsProps>();
  const clinicId = urlParams.clinicId ?? '';
  const id = urlParams.id ? parseInt(urlParams.id) : 0; 
  const { data: informationData, isError: isInformationError, isPending: isInformationPending } = useGetInformation(id);
  const [information, setInformation] = useState<Information>({
    id: 1,
    title: '',
    body: '',
    publishDatetime: '',
    status: 0,
    read: 0,
  });

  useEffect (() => {
    if(isInformationError){
      console.error(isInformationError)
    return
    }
    if(!isInformationPending && informationData !== undefined){
      setInformation(informationData.information);
    } 
  }, [isInformationError, informationData, isInformationPending])

  if (isInformationPending) {
    return (
      <OperationProof initial={true}/>
    )
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Layout>
          <PageBackButton link={`/${clinicId}/setting/news`} />
          <Box sx={{ background: "white", borderRadius: "16px", p: 3, mt: 1  }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "8px" }}>
              <Box sx={{ width: "40px", height: "40px", borderRadius: "50%" , background: theme.palette.secondary.main, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <NotificationsIcon sx={{ color: theme.palette.text.primary }}/>
              </Box>
              <Typography sx={{ fontWeight: "600", fontSize: "18px", color: theme.palette.text.primary }}>お知らせ</Typography>
            </Box>
            <Box sx={{ mt: 3, px: 4 }}>     
              <Typography sx={{ fontWeight: "600", fontSize: "20px", color: theme.palette.text.primary, mb: 1 }}>
                {information.title}
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#9D9D9D" }}>
                {information.publishDatetime}
              </Typography>  
              <Box sx={{ pt: 3, whiteSpace: 'pre-wrap' }}>
                {parse(information?.body ?? '')}
              </Box>                          
            </Box>
          </Box>
        </Layout>
      </ThemeProvider>
    </>
  )
}
export default SettingNewsListPost;
