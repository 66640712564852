import React, { useState } from 'react'
import { Typography, Box, Stack, Button, Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
import theme from '../../style/theme'
import type { Photo } from 'api/photo/responseType'
import PhotoFrame from 'components/PhotoFrame'
import { useParams } from "react-router-dom"
import { DiffPhoto , DiffPhotoModalProps } from 'components/DiffPhoto'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import { ConfirmModal, ConfirmModalProps } from 'components/ConfirmModal'
import { useDeleteExamination } from '../../api/photo'
import Snackbar from '@mui/material/Snackbar'
import { calculateThatTimeAge } from 'lib/patientService'
import { getAlterPath, PatientPhotoItemProps } from 'types/PhotoObject'
import { styled } from '@mui/material/styles'
import { angleFlags } from 'lib/ArrayService'
import CarouselPhoto, { CarouselPhotoModalProps, ViewerTooltipFlags } from 'components/CarouselPhoto'
import { handleEditedPhotoDownload } from 'lib/photoService'
import useResetQuery from 'api/useResetQuery'

type ConfirmModalOnCloseProps = {
  modalStatus: string
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#242424',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px',
    fontSize: theme.typography.pxToRem(12),
    border: '#242424',
  },
}))

const PatientPhotoFaceItem = (props : PatientPhotoItemProps) => {
  const urlParams = useParams<{clinicId: string | undefined}>()
  const clinicId = urlParams.clinicId ?? ''
  const { examinationData, patientData, angleNameGetter, handleExport, exportProcessing, examinationArray, examinationIndex } = props
  const [diffPhotoModalConfig, setDiffPhotoModalConfig] = useState<DiffPhotoModalProps | undefined>()
  const [carouselPhotoModalConfig, setCarouselPhotoModalConfig] = useState<CarouselPhotoModalProps | undefined>()
  const {mutate: deleteExamination} = useDeleteExamination()
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [snackBarFlag, setSnackBarFlag] = useState('')
  const navigate = useNavigate()
  const resetQuery = useResetQuery()
  const [confirmModalConfig, setConfirmModalConfig] = useState<ConfirmModalProps | undefined>()
  const [ tooltipSettingOpen, setTooltipSettingOpen ] = useState(false)

  const targetAngles = [32, 30, 33, 31, 34, 35, 36, 37, 38]
  const [ tooltipViewerOpen, setTooltipViewerOpen ] = useState<ViewerTooltipFlags>(angleFlags(targetAngles))

  const imageGetter = (angle: number) => {
    return examinationData.photo?.find((photo:Photo) => photo.angle === angle)
  }

  const handleViewerOpen = async (angleindex: number) => {
    const photoMatrix: (Photo | undefined)[][] = examinationArray.map((exam) => {
      return targetAngles.map(angle => {
        return exam.photo.find(item => angle === item.angle)
      })
    })
    await new Promise<CarouselPhotoModalProps>((resolve) => {
      setCarouselPhotoModalConfig({
        onClose: resolve,
        patientData: patientData,
        examinations: examinationArray,
        carousel: photoMatrix,
        initialAngleIndex: angleindex,
        initialDateIndex: examinationIndex,
        angleNameGetter: angleNameGetter,
        tableName: 'photos',
        setSnackBarOpen,
        setSnackBarMessage,
        setSnackBarFlag
      })
    })
    setCarouselPhotoModalConfig(undefined)
  }

  const handleDiffOpen = async (num: number) => {
    await new Promise<DiffPhotoModalProps>((resolve) => {
      setDiffPhotoModalConfig({
        onClose: resolve,
        patientData: patientData,
        examinationData: examinationData,
        angleNum: num,
        angleNameGetter: angleNameGetter
      })
    })
    setDiffPhotoModalConfig(undefined)
  }

  const handleSnackClose = () => {
    setSnackBarOpen(false)
  }

  const handleExaminateDelete = async () => {
    const ret = await new Promise<ConfirmModalOnCloseProps>((resolve) => {
      setConfirmModalConfig({
        onClose: resolve,
        title: '本当に削除しますか？',
        body: '同一日全ての写真が削除されます。'
      })
    })
    setConfirmModalConfig(undefined)
    if (ret.modalStatus === 'ok' && patientData) {
      deleteExamination(
        { patientId: patientData.id, examinationId: examinationData.id },
        {
          onSuccess: (response) => {
            if (response.status === 'OK') {
              resetQuery(['patientList'])
              resetQuery(['patientDetail', patientData.id])
              navigate(0)
            } else {
              setSnackBarOpen(true)
              setSnackBarMessage('エラー 422')
              setSnackBarFlag('error')
            } 
          },
          onError: (error) => {
            console.error(error)
            setSnackBarOpen(true)
            setSnackBarMessage('エラー 422')
            setSnackBarFlag('error')
          }
        }
      )
    }
    if (ret.modalStatus === 'cancel') {
      return
    }    
  }  

  const TooltipSettingContent = () => {
    const style = {
      p: 0,
      '&:hover': { color: theme.palette.primary.main, backgroundColor: 'transparent' }
    }
    const disabledStyle = {
      p: 0
    }
    return patientData ?
    (
      <>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
          onMouseDown={() => navigate(`/${clinicId}/patient/upload/` + patientData?.id +"/"+ examinationData.id)}
        >
          <Typography color='white' sx={style}>
            編集
          </Typography>
        </Button>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
          onMouseDown={() => navigate(`/${clinicId}/patient/swap/` + patientData?.id +"/"+ examinationData.id)}
        >
          <Typography color='white' sx={style}>
            移動
          </Typography>
        </Button>
        <Tooltip
          disableHoverListener={!exportProcessing}
          disableFocusListener={!exportProcessing}
          disableTouchListener={!exportProcessing}
          disableInteractive={true}
          title="進行中のエクスポートがあります。同時に複数のエクスポートは実行できません。"
        >
          <Box>
            <Button
              sx={{width: 120, justifyContent: 'left'}}
              disabled={!!exportProcessing}
              onMouseDown={() => {
                handleExport(patientData, examinationData)}
              }
            >
              <Typography color={exportProcessing ? 'gray' : 'white'} sx={exportProcessing ? disabledStyle : style}>
                エクスポート
              </Typography>
            </Button>
          </Box>
        </Tooltip>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
          onMouseDown={handleExaminateDelete}>
          <Typography color='white' sx={style}>
            削除
          </Typography>
        </Button>
      </>
    ) : (<></>)
  }

  const handleButtonSettingClicked = () => {
    setTooltipSettingOpen(!tooltipSettingOpen)
  }

  const handleTooltipSettingClose = () => {
    setTooltipSettingOpen(false)
  }

  const TooltipViewerContent = (props: {image: Photo | undefined, angleindex: number}) => {
    const {image, angleindex} = props
    const style = {
      p: 0,
      '&:hover': { color: theme.palette.primary.main, backgroundColor: 'transparent' }
    }
    return image ?
    (
      <>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
          onMouseDown={() => handleViewerOpen(angleindex)}
        >
          <Typography color='white' sx={style}>
            閲覧
          </Typography>
        </Button>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
          onMouseDown={() => handleDiffOpen(image.angle)}
        >
          <Typography color='white' sx={style}>
            比較
          </Typography>
        </Button>
        <Button
          sx={{width: 120, justifyContent: 'left'}}
                    onMouseDown={() => handleEditedPhotoDownload(patientData.id, 'photos', image.id, {setSnackBarOpen, setSnackBarMessage, setSnackBarFlag})}
        >
          <Typography color='white' sx={style}>
            ダウンロード
          </Typography>
        </Button>
      </>
    ) : (<></>)
  }

  const handleButtonViewerClicked = (angle: number) => {
    const flags = structuredClone(tooltipViewerOpen)
    flags[angle] = !tooltipViewerOpen[angle]
    setTooltipViewerOpen(flags)
  }

  const handleTooltipViewerClose = (angle: number) => {
    const flags = structuredClone(tooltipViewerOpen)
    flags[angle] = false
    setTooltipViewerOpen(flags)
  }

  const photoExists = examinationData.photo?.filter((photo:Photo) => targetAngles.includes(photo.angle)).length !== 0

  return photoExists && patientData ? (
    <>
      <Stack sx={{ border: '2px solid #F4F4F4', alignItems: "center", mb: 2 }} direction = "row">
        <Stack sx={{ width: '150px', display: "flex", alignItems: "center", backgroundColor: "#F4F4F4", height: "134px", justifyContent: "center" }} direction = "column">
        <Typography fontWeight="bold" color="#364F4E">
            {examinationData.examinationDate}
          </Typography>
          <Typography fontSize={12} color="#364F4E">
            {calculateThatTimeAge(patientData.birthday, examinationData.examinationDate)}
          </Typography>
          <Stack sx={{ columnGap: 1, mt: 1 }} direction="row">     
            <HtmlTooltip
              title={
                <TooltipSettingContent />
              }
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={tooltipSettingOpen}
              onBlur={handleTooltipSettingClose}
            >
              <Button
                variant="outlined"
                sx={{ py: 0 ,background: theme.palette.primary.main , color: "white", borderRadius: "30px", width: "24px", fontSize: "12px", "&:hover": { backgroundColor: "white", color: theme.palette.primary.main, borderColor: theme.palette.primary.main } }}
                onClick={handleButtonSettingClicked}>
                設定
              </Button>
            </HtmlTooltip>
          </Stack>
        </Stack>
        <Stack sx={{ width: 'calc(100% - 120px)', scrollbarWidth: "none", msOverflowStyle :"none" }} overflow="scroll" whiteSpace="nowrap">
          <Scrollbars style={{ width: '100%', height: '134px'}}>
            <Stack alignItems="center" justifyContent="flex-start" gap={0} direction="row" width="calc(168px * 9)">
              {
                targetAngles.map((angle, index) => {
                  const image = imageGetter(angle)
                  return (
                    <HtmlTooltip
                      title={
                        <TooltipViewerContent image={image} angleindex={index}/>
                      }
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, -20],
                              },
                            },
                          ],
                        },
                      }}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      open={tooltipViewerOpen[angle]}
                      onBlur={() => handleTooltipViewerClose(angle)}
                      key={angle}
                    >
                      <Button 
                        onClick={() => handleButtonViewerClicked(angle)}
                        disabled={!imageGetter(angle)}
                        key={angle}
                      >
                        <Box>
                          {
                            [32, 30, 33].includes(angle) ?
                            <PhotoFrame
                              image={imageGetter(angle)}
                              alterPath={getAlterPath(angle)}
                            /> :
                            <PhotoFrame
                              image={imageGetter(angle)}
                              angleName={angleNameGetter(angle)}
                              isTextAlter={true}
                            />
                          }
                        </Box>
                      </Button>
                    </HtmlTooltip>
                  )
                })
              }
            </Stack>
          </Scrollbars>
        </Stack>
      </Stack>   
      {diffPhotoModalConfig && <DiffPhoto {...diffPhotoModalConfig} />}
      {carouselPhotoModalConfig && <CarouselPhoto {...carouselPhotoModalConfig} />}
      {confirmModalConfig && <ConfirmModal {...confirmModalConfig} />}
      <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
          }}
          open={snackBarOpen}
          onClose={handleSnackClose}
      >
        <Alert
          severity={snackBarFlag === 'error' ? 'error' : 'success'}
          sx={{ width: '100%' }}
          variant="filled"
        >{snackBarMessage}</Alert>
      </Snackbar>
    </>
  ) : (
    <></>
  )
}
export default PatientPhotoFaceItem
